import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['element']
  static values = { content: String, trigger: String }

  connect() {
    tippy(this.element, {
      content: this.contentValue,
      allowHTML: true,
      placement: 'left',
      maxWidth: 800,
      theme: 'light',
      interactive: true,
      trigger: this.hasTriggerValue ?  this.triggerValue : 'mouseenter focus'
    });
  }
}
