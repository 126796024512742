import ApplicationController from './application_controller'
import { utils } from './utils/modal'
/*
 * IMPORTANT: If you want to use this JS controller, you probably need to use
 * Tailwind::ModalHelper#tw_modal helper.
 *
 * EXAMPLE using the helper:
 *   <%= tw_modal open_by_default: true,
 *              show_loading_icon: true do %>
 *     Modal body content here...
 *   <% end %>
 *
 * JS Controller values:
 *   uniqId: A string used as an unique ID to generate different JS events.
 *   deleteModalAfterClosing: If "true", the element will be deleted once the modal is closed.
 *   openOnInit: If "true", the modal is opened by default.
 *   showLoadingIcon: If "true", show a loading spinner icons.
 *
 * JS Controller Events:
 *   "tw_modal:${uniqId}:hide_loading_icon": Hide the "loading spinner" icon.
 *   "tw_modal:${uniqId}:close_modal": Close the modal.
 *
 * (NOT RECOMMENDED) Example without using the helper:
 *   see "app/views/components/_modal.html.erb"
 */
export default class extends ApplicationController {
  static targets = [
    'content',
    'overlay',
    'modal',
    'loadingIconContainer'
  ]

  static values = {
    uniqId: String,
    deleteModalAfterClosing: Boolean,
    openOnInit: Boolean,
    showLoadingIcon: Boolean,
    url: String,
  }

  #modalAnimationDuration = 300
  #hasModalBeenOpened = false
  #contextId = null

  initialize () {
    this.#contextId = this.uniqIdValue || Date.now();

    if (this.hasLoadingIcon()) {
      this.showLoadingIcon()
    }

    if (this.openOnInitValue === true) {
      this.showModal()
    }

    this.addEventListeners()
  }

  disconnect () {
    document.body.classList.remove('overflow-hidden')

    const contextId = this.#contextId
    document.removeEventListener(utils.getHideLoadingIconEventName({contextId}), this.hideLoadingIcon)
    document.removeEventListener(utils.getShowLoadingIconEventName({contextId}), this.showLoadingIcon)
    document.removeEventListener(utils.getOpenEventName({contextId}), this.showModal)
    document.removeEventListener(utils.getCloseEventName({contextId}), this.hideModal)
  }

  addEventListeners() {
    const contextId = this.#contextId
    document.addEventListener(utils.getHideLoadingIconEventName({contextId}), () => this.hideLoadingIcon());
    document.addEventListener(utils.getShowLoadingIconEventName({contextId}), () => this.showLoadingIcon());
    document.addEventListener(utils.getOpenEventName({contextId}), () => this.showModal());
    document.addEventListener(utils.getCloseEventName({contextId}), () => this.hideModal());

    this.overlayTarget.addEventListener('click', (event) => {
      (event.target === this.overlayTarget) && this.hideModal()
    })
  }

  async loadUrl() {
    try {
      const response = await fetch(this.urlValue, {
        method: 'GET',
        headers: {
          'X-Modal': 'on',
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const html = await response.text()
      this.contentTarget.innerHTML = html
    } catch (error) {
      console.error(error)
    } finally {
      if (this.hasLoadingIcon()) {
        this.hideLoadingIcon()
      }
    }
  }

  #showElement (target) {
    target.classList.remove('hidden')
    window.requestAnimationFrame(() => {
      target.classList.remove('opacity-0')
      target.classList.add('opacity-100')
    })
  }

  #hideElement (target) {
    target.classList.remove('opacity-100')
    target.classList.add('opacity-0')
    window.requestAnimationFrame(() => {
      target.classList.add('hidden')
    })
  }

  showModal () {
    if (!this.#hasModalBeenOpened && !!this.urlValue) {
      this.loadUrl()
    }

    this.#showElement(this.overlayTarget)
    this.#showElement(this.modalTarget)

    document.body.classList.add('overflow-hidden')

    this.#hasModalBeenOpened = true
  }

  hideModal () {
    this.#hideElement(this.overlayTarget)
    this.#hideElement(this.modalTarget)

    document.body.classList.remove('overflow-hidden')

    if (this.deleteModalAfterClosingValue) {
      setTimeout(() => this.element.remove(), this.#modalAnimationDuration + 1)
    }
  }

  showLoadingIcon () {
    this.loadingIconContainerTarget.classList.remove('hidden')
  }

  hideLoadingIcon () {
    this.modalTarget.classList.add('loading-ok')
    this.loadingIconContainerTarget.classList.add('hidden')
  }

  hasLoadingIcon () {
    return this.showLoadingIconValue && this.hasLoadingIconContainerTarget
  }
}
