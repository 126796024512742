import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "content"];
  connect() {
    this.isOpen = this.element.open || false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.toggleAriaExpanded();
    this.toggleDisplay();
    this.toggleChevron();
  }

  toggleAriaExpanded() {
    this.triggerTarget.setAttribute("aria-expanded", this.isOpen);
  }
  toggleChevron() {
    this.triggerTarget.firstElementChild.classList.toggle("rotate-180");
  }
  toggleDisplay() {
    this.contentTarget.classList.toggle("hidden");
  }
}
