import "controllers";
import { Turbo } from "@hotwired/turbo-rails";
import ProgressBar from "./utils/progress-bar";
import 'trix';
import '@rails/actiontext';


const progressBar = new ProgressBar({
  progressBarContainerId: "progress-bar",
  progressBarId: "progress",
});

['turbo:before-fetch-request', 'turbo:submit-start'].map(event => {
  document.addEventListener(event, () => {
    progressBar.reset();
    progressBar.animateProgress();
  });
});

['turbo:before-fetch-response', 'turbo:submit-end'].map(event => {
  document.addEventListener(event, () => progressBar.animateProgress(1));
})

Turbo.session.drive = false
