import ApplicationController from './application_controller';
export default class extends ApplicationController {
    static targets = ["status"]
    static values = {
        roundId: String,
        latestReport: String,
        checkInterval: { type: Number, default: 3000 }, // 3 secondes
        maxAttempts: { type: Number, default: 120 } // 6 minutes maximum
    }

    connect() {
        if (this.hasStatusTarget && this.isStatusPending()) {
            this.startPolling()
        }
    }

    disconnect() {
        if (this.pollingTimeout) {
            clearTimeout(this.pollingTimeout)
        }
    }

    isStatusPending() {
        return this.statusTarget.dataset.status === "pending"
    }

    startPolling() {
        this.attemptCount = 0
        this.checkStatus()
    }

    async checkStatus() {
        if (this.attemptCount >= this.maxAttemptsValue) {
            console.log("Maximum polling attempts reached")
            return
        }

        try {
            const response = await fetch(`/rounds/${this.roundIdValue}/all_documents/${this.latestReportValue}/document_status`)
            const data = await response.json()

            if (data.status === "ready" || data.status === "error") {
                window.location.reload()
            } else if (data.status === "pending") {
                this.attemptCount++
                this.pollingTimeout = setTimeout(
                    () => this.checkStatus(),
                    this.checkIntervalValue
                )
            }
        } catch (error) {
            console.error("Error checking document status:", error)
        }
    }
}