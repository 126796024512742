import ApplicationController from './application_controller'

export default class TurboFrameMissingController extends ApplicationController {
  static targets = ['label']

  connect () {
    this.labelTargets.forEach(this.addEvent);
  }

  addEvent (label) {
    if (label.querySelector('input[type="radio"]')) {
      label.addEventListener('mousedown', function(e) {
        const radio = label.querySelector('input[type="radio"]');
        const wasChecked = radio.checked;
        radio.turnOff = wasChecked;
        radio.checked = !wasChecked;
      });

      label.addEventListener('click', function(e) {
        const radio = label.querySelector('input[type="radio"]');
        radio.checked = !radio.turnOff;
        radio['turning-off'] = !radio.turnOff;
      });
    }
  }
}
